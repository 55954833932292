@import "sass-lib";

@media (min-width: 0px) and (max-width: 1280px) {
  .box-tags .drop-tags .in-drop-tags {
    max-height: 550px;
  }
}
@media (min-width: 0px) and (max-width: 1020px) {
	h2{font-size: 28px;}
	h3{font-size: 24px;}
	.wrapper{padding: 0 40px;}

	header{
		.nav-right{
			.box-search{margin-left: 10px;}
		}
	}

	.label{font-size: 12px;padding: 8px 10px;}
	.paddsecbot{padding-bottom: 30px;}

	.box-slider-home{
		padding: 175px 0 165px;
		.left{
			padding: 0 40px;top: 220px;
			h1{font-size: 32px;line-height: 48px;}
		}
		.sliderhome{margin-left: -100px;}
		.arrow-slidehome{
			.arrow-slide{
				a{
					width: 10px;height: 16px;
					&.prev,&.next{background-size: 100%;}
					&.prev{margin-right: 20px;}
					&.next{margin-left: 20px;}
				}
			}
		}
	}
	.sliderhome{
		.list{
			margin: 0 10px;
			.desc{
				padding: 0 25px 25px;
				h4{font-size: 14px;line-height: 20px;height: 40px;}
				.label{margin-bottom: 10px;}
			}
		}
	}
	.side-th{
		figure{width: 40%;}
		.text{
			width: 55%;
			p{font-size: 13px;line-height: 22px;height: 44px;}
			.tblue{font-size: 13px;}
		}
	}
	.in-box-news{
		.potrait-th{
			figure{width: 40%;}
			.text{
				width: 55%;
				p{font-size: 13px;line-height: 22px;height: 44px;}
			}

			&:nth-child(n+3){display: none;}
		}
	}
	.potrait-th{
		.text{
			.tblue{font-size: 13px;}
		}
	}
	.box-news{
		> .left{
			.text{padding-bottom: 30px;}
		}
	}
	.box-tag{
		padding: 35px 30px;
		.left,.right{width: 100%;}
		.left{margin-bottom: 20px;}
	}
	.slidepopular{
		.slick-arrow{
			width: 40px;height: 40px;
			&.slick-prev,&.slick-next{
				&:before{width:9px;height: 14px;background-size: 100%;}
			}
		}
	}
	.highlight{
		padding-top: 60px;padding-bottom: 80px;
		.sliderwp{left: 25px;}
	}
	.top-highlight{
		margin-bottom: 10px;
		.left{
			a{font-size: 14px;}
		}
		.arrow-slide{
			a{
				width:15px;height:16px;background-size: 10px;
				&.prev{margin-right: 20px;}
				&.next{margin-left: 20px;}
			}
		}
	}
	.sliderwp{
		height: 250px;
		.inner-sliderwp{
			.list{
				margin-top: 18px;
				figure{width: 330px;}
				.desc{
					padding:0 30px 25px;
					.label{margin-bottom: 10px;}
				}
				.btn-play{width: 50px;height: 50px;}
			}
		}
	}

	.box-sosmed{
		a{background-size: 100%;width: 25px;height: 25px;margin-left: 10px;}
	}

	.box-search-nav{
		width: 20px;height: 20px;margin-left: 30px;
		&:before{left:-15px;z-index: 5;}
		> span{display: block;position: absolute;top: 0;right: 0;bottom: 0;width: 20px;height:21px;background: url('../images/material/ic_search_blue.png')no-repeat center;border: none;z-index: 1;}
		input[type=text]{
			position: absolute;top: -28px;right: -10px;height: 40px;padding: 0 0 0 15px;font-size: 13px;z-index: 2;display: none;height: 75px;text-align: right;width: 200px;margin-right: 100px;
			/*@include andplaceholder{font-style: italic;}*/
		}
		.btn-search-nav{z-index: 3;display: none;}
	}
	.box-subnav{
		.linev{margin: 0 15px;}
	}
	.box-thumb{
		.ls-thumb{
			p{font-size: 14px;line-height: 24px;max-height: 48px;overflow: hidden;}
			figure{
				.label{left: 25px;}
			}
			.desc{padding: 25px;}

			&.ls-video{
				figure{
					&:after{width:60px;height: 60px;}
				}
			}
		}
	}
	.box-rounded{padding: 30px 20px;}
	.banner-detail{
		margin-bottom: 30px;
		h3{font-size: 22px;line-height: 30px;}
		.label{margin-bottom: 10px;}
		.text{bottom: 20px;left: 30px;right: 30px;}
	}
	.contentwp{
		h6{width: 100%;margin-right: 0;font-size: 24px;line-height: 34px;}
		blockquote{
			padding:80px 40px;font-size: 22px;line-height: 36px;
			&:before{left:40px;top: 30px;background-size: 100%;width: 27px;}
			&:after{right:40px;bottom: 30px;background-size: 100%;width: 27px;}
		}
	}
  .box-tags .drop-tags .in-drop-tags {
    max-height: 785px;
    width: 80%;
  }
	footer{
		.foot-left{font-size: 14px;line-height: 25px;}
	}
}